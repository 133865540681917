<template>
    <div>
        <div class="row align-items-center" v-if="application && application.application_type">
            <div class="col-8">
                <h4 class="mb-0 text-uppercase">
                    Make Payment for
                    <em class="text-danger">
                        {{ application.application_type.name }}
                    </em>
                </h4>
            </div>
            <div class="col-4 text-right">
                <router-link to="/applications" class="btn btn-sm btn-danger">
                    <i class="fa fa-reply"></i> Back
                </router-link>
            </div>
        </div>

        <div class="container ct-example-row" v-if="! showAddPayment">
            <div class="row" v-if="! isLoadingBlade">
                <div class="col-lg-12">
                    <ul
                        class="list-unstyled"
                        v-if="application && application.application_type &&
                            application.application_type.has_payment"
                    >
                        <li>
                            <hr class="my-4">
                             <h4
                                class="text-center text-danger"
                                v-if="application.license_year && license.surcharge"
                             >
                                Note: If you make payment on or before 31st Decemeber
                                {{ application.license_year - 1 }},
                                and you have the RRR number, kindly proceed to add the RRR payment.
                                As the surge amount will not affect you.
                            </h4>
                            <br>
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.application_type.name }}
                                </em>
                            </h3>
                            <h3 class="text-center"
                                v-if="licenseYear"
                            >
                                License Year: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ licenseYear }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ totalAmount | formatPrice }}
                                </em>
                            </h3>

                            <hr class="my-4">

                            <h1 class="text-center">
                                Payment Details
                            </h1>
                            <hr class="my-4">
                            <h3
                                v-show="application.application_type.has_payment.has_breakdowns.length > 0"
                                v-for="breakdown in application.application_type.has_payment.has_breakdowns"
                                :key="breakdown.id"
                            >
                                {{ breakdown.name }}: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ breakdown.amount | formatPrice }}
                                </em>
                                <br>
                            </h3>

                            <h3 v-if="application.postage_fee && application.postage_fee.fee">
                                {{ isLogs ? 'Administrative Charges' : 'Postage Fee' }}
                                <em class="text-danger">{{ application.postage_fee.country ?
                                        'for '+application.postage_fee.country.name : '' }}</em>: &nbsp;
                                <em class="text-capitalize font-weight-light right text-danger">
                                    {{ application.postage_fee.fee | formatPrice }}
                                </em>
                            </h3>

                            <h3 v-if="isFullAqReg">
                                Postage Fee
                                {{ application.country_postage_fee ?
                                        'for '+application.country_postage_fee.name : '' }}: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ application.country_postage_fee.amount | formatPrice }}
                                </em>
                            </h3>

                            <h3
                                v-if="surcharge && surcharge.has_surcharge && ! application.license_year"
                                class="text-danger"
                            >
                                Surcharge (For Late Registration): &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ surcharge.amount | formatPrice }}
                                </em>
                            </h3>

                            <h3
                                class="text-danger"
                                v-if="application.license_year && license.surcharge"
                            >
                                Surcharge (Late Payment): &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ application.application_type.has_payment.surcharge | formatPrice }}
                                </em>
                            </h3>

                            <hr class="my-4">
                            <h3>
                                Total: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ totalAmount | formatPrice }}
                                </em>
                            </h3>
                            <hr class="my-4">

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>

                            <h3 class="text-danger text-center"
                                v-if="showPaymentForm"
                            >
                                Note: If you want to generate RRR for this payment and pay at designated banks, kindly click on
                                <em class="text-dark">Black</em> button. However, if you have made payment already, you can add your
                                payment to the application by clicking on the <em class="text-primary">Purple</em> button.
                                <hr>
                                Note: When you Generate RRR, you are to proceed to the designated banks to make payment. After
                                succesful payment, click on the <em class="text-primary">I have Paid, Add RRR Now</em> button
                                to add your payment.
                            </h3>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12">
                    <hr class="my-4" v-if="showPaymentForm">

                    <form
                        :action="remitaPaymentUrl" method="POST"
                        v-if="showPaymentForm"
                    >
                        <input
                            type="hidden"
                            v-for="(item, index) in paymentRecords"
                            :key="index"
                            :name="item.title"
                            :value="item.value"
                        />

                        <base-button
                            type="success"
                            nativeType="submit"
                        >
                            <i class="fa fa-credit-card"></i>
                            {{ isloading ? 'Please Wait...' : 'Pay Directly Online Now'}}
                        </base-button>

                        <div class="right">
                            <base-button
                                type="dark"
                                @click="addPayment(orderId, true)"
                            >
                                <i class="fa fa-history"></i>
                                Generate RRR &amp; Pay at the Bank
                            </base-button>

                            <base-button
                                type="primary"
                                @click="addPayment(orderId, false)"
                            >
                                <i class="fa fa-plus"></i>
                                I have Paid, Add RRR Now
                            </base-button>
                        </div>

                    </form>
                </div>
            </div>

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>
        </div>

        <AddPayment
            v-bind:orderNumber="orderNumber"
            v-bind:app_id="application.id"
            v-bind:serviceType="application.service_type"
            v-bind:applicationName="application.application_type.name"
            v-bind:applicationCode="application.application_type.code"
            v-bind:applicationAmount="totalAmount"
            v-bind:license="license"
            v-bind:licenseYear="licenseYear"
            v-on:removeAddPayment="removeAddPayment"
            v-bind:removeAddPayment="removeAddPayment"
            :isDirectPaymentPage="isDirectPaymentPage"
            :showGeneratePage="showGeneratePage"
            v-if="showAddPayment"
        />

    </div>



</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AddPayment from './PaymentDetails/AddPayment';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            AddPayment
        },
        name: 'ApplicationPaymentDetails',
        props: ['application', 'loadApplication'],
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                orderId: null,
                showPaymentForm: false,
                showAddPayment: false,
                orderNumber: null,
                id: this.$route.params.id,
                isLoadingBlade: false,
                isDirectPaymentPage: false,
                showGeneratePage: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters([ 'loadingBladeUrl','remitaPaymentUrl', 'remitaMerchantId',
                'remitaPaymentCallbackUrl', 'remitaApiKey']),
            gethash() {
                var sha512 = require('js-sha512');
                let serviceType = this.application.service_type;
                let text = this.remitaMerchantId + serviceType.remita_code + this.orderId
                    + this.totalAmount + this.remitaPaymentCallbackUrl + this.remitaApiKey;
                return sha512(text);
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            surcharge() {
                return {
                    'has_surcharge' : this.application && (this.application.surcharge == 'yes'),
                    'amount' : this.application.application_type.has_payment.surcharge
                };
            },
            license() {
                let currentYear = this.getCurrentYear();// get current year
                let totalAmount = (parseInt(this.application.application_type.has_payment.amount) +
                                    parseInt(this.application.application_type.has_payment.surcharge));
                // let surcharge = this.application.license_year && this.application.license_year <= currentYear; // old function
                let surcharge = this.extendLicenseSurcharge(this.application.license_year, currentYear) ; // check for surcharge
                let amount = surcharge ? totalAmount : parseInt(this.application.application_type.has_payment.amount);// get exact amount
                return {
                    "amount": amount,
                    "surcharge": surcharge,
                    "surcharge_amount": parseInt(this.application.application_type.has_payment.surcharge),
                    "license_year": (this.application.license_year ? this.application.license_year : null)
                };
            },
            totalAmount() {// get total amount
                let log = this.application.has_good_standing;// get logs details
                if(log && log.postage_fee && log.postage_fee.fee) {// Logs only
                    return parseInt(this.application.application_type.has_payment.amount) + parseInt(log.postage_fee.fee);
                } else if(this.application.postage_fee && this.application.postage_fee.fee) {// Eics Only
                    return parseInt(this.application.application_type.has_payment.amount) +
                            parseInt(this.application.postage_fee.fee);
                } else if(this.application.application_type && this.application.application_type.code == 'full-aq-reg') {// Full without AQ Only
                    let amount = parseInt(this.application.application_type.has_payment.amount);
                    let postage = parseInt(this.application.country_postage_fee.amount);
                    return amount + postage;
                } else if(this.application.application_type && this.application.application_type.code == 'additional-pgq') {// AQ Only
                    let amount = parseInt(this.application.application_type.has_payment.amount);
                    let surcharge = parseInt(this.application.application_type.has_payment.surcharge);
                    return (this.application.surcharge == 'yes') ?  (amount + surcharge) : amount;
                } else if(this.application.amount) {
                    return parseInt(this.application.amount);
                } else {
                    let totalAmt = (parseInt(this.application.application_type.has_payment.amount) +
                                            parseInt(this.application.application_type.has_payment.surcharge));
                    let currentYear = this.getCurrentYear();// get current year
                    // let surcharge = this.application.license_year ? this.application.license_year <= currentYear : false; // old function
                    let surcharge = this.extendLicenseSurcharge(this.application.license_year, currentYear) ; // check for surcharge
                    return surcharge ? totalAmt : parseInt(this.application.application_type.has_payment.amount);
                }
            },
            paymentRecords() {// get payment records
                if(this.application && this.application.user_data) {
                    let userData = this.application.user_data;
                    let fullName = userData.first_name+' '+ userData.last_name;
                    let serviceType = this.application.service_type;
                    let user = userData.user;
                    return [
                        {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                        {'title' : 'serviceTypeId', 'value' : serviceType.remita_code},
                        {'title' : 'amt', 'value' : this.totalAmount},
                        {'title' : 'responseurl', 'value' : this.remitaPaymentCallbackUrl},
                        {'title' : 'hash', 'value' : this.gethash},
                        {'title' : 'payerName', 'value' : fullName},
                        {'title' : 'paymenttype', 'value' : ''},
                        {'title' : 'payerEmail', 'value' : user.email},
                        {'title' : 'payerPhone', 'value' : user.phone},
                        {'title' : 'orderId', 'value' : this.orderId},
                    ]
                }                
            },
            licenseYear() {// get license year
                return this.application.license_year ? parseInt(this.application.license_year) : null;
            },
            isFullAqReg() {
                return this.application.application_type && this.application.application_type.code == 'full-aq-reg';
            },
            isLogs() {// check if application is good standing
                return this.application && this.application.application_type && (this.application.application_type.code == "cert-good-standing");
            }
        },
        methods: {
            ...mapActions(['getUserApplicationDetails', 'createUserApplication', 'fetchUserProfile',
            'saveApplicationTransaction', 'updateTransNo']),
            extendLicenseSurcharge(licenseYear, currentYear) {
                let if2025 = (licenseYear == 2025);
                let ifDateLess8th = moment().isBetween('2025-01-01', '2025-01-08');
                if(if2025 && ifDateLess8th) {
                    return false;
                }
                return (licenseYear && licenseYear <= currentYear);
            },
            getPostage(log) {
                return log.postage_fee ? log.postage_fee : false;
            },
            getCurrentYear() {
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY');
            },
            updateTransactionNo() {// create application
                this.isLoadingBlade = true;
                this.updateTransNo({'id' : this.id, 'license_year' : this.licenseYear}).then((res) => {//update trans no
                    this.isLoadingBlade = false;
                    this.orderId = res.trans_no;
                    this.showPaymentForm = true;
                    this.loadApplication();// load application
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    let message = 'Network Failure!, kindly reload the page...';
                    swal.fire('Network Failure!', message, 'warning');
                });
            },
            removeAddPayment() {// remove add payment
                this.loadApplication();// load application
                return this.showAddPayment = false;
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            makePayment() {// online payment function
                return this.createApplication();// create application
            },
            addPayment(number, status) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
                this.isDirectPaymentPage = true;
                this.showGeneratePage = status;
            }
        },
        mounted() {
            this.updateTransactionNo(); // update transaction number
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>